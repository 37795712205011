import { facePaint } from '../../../../domain/theme/utils/facePaint';

const { css } = require('@emotion/react');
const { theme } = require('../../../../domain/theme');

const baseStyles = {
  container: css`
    position: relative;
    width: 100%;
  `,

  imageContainer: css`
    width: 100%;
    position: relative;
  `,

  textContainer: css`
    width: 95%;
    margin-left: 10px;
    ${theme.mq.tablet} {
      margin-left: unset;
    }
  `,
  card: {}
};

const sizeImageContainer = css`
  width: 100%;
  position: relative;
  margin-bottom: 15px;
`;

const getStylesBySize = {
  large: options => ({
    container: css`
      width: 100%;
      position: relative;
    `,
    imageContainer: css`
      width: 100%;
      position: relative;
      margin-bottom: 15px;
      ${theme.mq.tablet} {
        picture:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 60%,
            rgba(0, 0, 0, 0.6) 100%
          );
          z-index: 0;
        }
      }
    `,
    textContainer: css`
      width: 100%;
      padding: 0 56px 0 30px;
      z-index: ${theme.zIndex('share')};
      ${theme.mq.tablet} {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 140px 30px 90px;
      }
      ${theme.mq.small_desktop} {
        padding: 0 142px 40px 90px;
      }
      ${theme.mq.large_desktop} {
        padding: 0 152px 40px 90px;
      }

      ${options.type === 'video' &&
      `
        ${theme.mq.tablet} {
          padding-left: 0;
          margin-left: 110px;
        }
        ${theme.mq.small_desktop} {
          margin-left: 130px;
        }
        ${theme.mq.large_desktop} {
          margin-left: 140px;
        }
        `}
    `,
    card: {
      image: {
        aspect: options.aspect || '16x9',
        videoIconSize: 'l'
      },
      text: {
        title: {
          style: css`
            font-size: 1.5rem;
            line-height: 1.5;
            ${theme.mq.tablet} {
              font-size: 1.625rem;
              line-height: 1.5;
            }
            ${theme.mq.small_desktop} {
              font-size: 2.125rem;
            }
            ${theme.mq.large_desktop} {
              font-size: 3rem;
              line-height: 1.143;
            }
          `
        },
        color: facePaint({
          color: [
            options.backgroundColor === 'black' ? '#fff' : '#000',
            '#fff',
            '#fff',
            '#fff'
          ]
        }),
        taxonomy: {
          borderColorLeft: facePaint({
            'border-left': [
              '1px solid #555',
              '1px solid #fff',
              '1px solid #fff',
              '1px solid #fff'
            ]
          }),
          borderColorRight: facePaint({
            'border-right': [
              '1px solid #555',
              '1px solid #fff',
              '1px solid #fff',
              '1px solid #fff'
            ]
          }),
          margin: '0 0 15px 0'
        }
      }
    }
  }),
  medium: options => ({
    container: baseStyles.container,
    imageContainer: sizeImageContainer,
    textContainer: baseStyles.textContainer,
    card: {
      image: {
        aspect: options.aspect || '4x3',
        videoIconSize: 's'
      },
      text: {
        title: {
          style: css`
            font-size: 1.375rem;
            letter-spacing: 0.1px;
            line-height: 1.273;
            ${theme.mq.small_desktop} {
              font-size: 1.75rem;
              letter-spacing: 0.3px;
              line-height: 1.214;
            }
          `
        },
        color: options.baseColor,
        taxonomy: {
          borderColorLeft: `border-left: ${options.baseBorderColor}`,
          borderColorRight: `border-right: ${options.baseBorderColor}`,
          margin: '0 0 15px 0'
        }
      }
    }
  }),
  small: options => ({
    container: baseStyles.container,
    imageContainer: sizeImageContainer,
    textContainer: baseStyles.textContainer,
    card: {
      image: {
        aspect: options.aspect || '16x9',
        videoIconSize: 's'
      },
      text: {
        title: {
          style: css`
            font-size: 1.5rem;
            line-height: 1.5;
            ${theme.mq.tablet} {
              font-size: 1.125rem;
              letter-spacing: 0.5px;
              line-height: 1.429;
            }
            ${theme.mq.small_desktop} {
              font-size: 1.375rem;
              letter-spacing: 0.1px;
              line-height: 1.273;
            }
          `
        },
        color: options.baseColor,
        taxonomy: {
          borderColorLeft: `border-left: ${options.baseBorderColor}`,
          borderColorRight: `border-right: ${options.baseBorderColor}`,
          margin: '0 0 15px 0'
        }
      }
    }
  }),
  third: options => ({
    container: baseStyles.container,
    imageContainer: sizeImageContainer,
    textContainer: css`
      width: 95%;
      padding-left: 20px;
      padding-right: 20px;
    `,
    card: {
      image: {
        aspect: options.aspect || '16x9',
        videoIconSize: 's'
      },
      text: {
        title: {
          style: css`
            font-size: 1.375rem;
            line-height: 1.273;
            ${theme.mq.tablet} {
              font-size: 1.125rem;
              letter-spacing: 0.5px;
              line-height: 1.429;
              line-height: 28px;
            }
            ${theme.mq.large_desktop} {
              font-size: 1.375rem;
              letter-spacing: 0.1px;
              line-height: 28px;
            }
          `
        },
        color: options.baseColor,
        taxonomy: {
          borderColorLeft: `border-left: ${options.baseBorderColor}`,
          borderColorRight: `border-right: ${options.baseBorderColor}`,
          margin: '0 0 15px 0'
        }
      }
    }
  }),
  list: options => ({
    container: css`
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      ${theme.mq.tablet} {
        justify-content: center;
        flex-direction: column;
      }
      margin-bottom: 20px;
    `,
    imageContainer: css`
      width: 160px;
      min-width: 160px;
      position: relative;
      svg {
        position: absolute;
        bottom: 0;
        margin: 10px;
      }
      ${theme.mq.small_desktop} {
        width: 100%;
      }
    `,
    textContainer: css`
      margin-left: 10px;
      max-width: 210px;
      ${theme.mq.small_desktop} {
        margin-top: 10px;
        margin-left: unset;
        max-width: unset;
      }
    `,
    card: {
      image: {
        aspect: options.aspect || '16x9',
        videoIconSize: 's',
        icon: ['vertical_slideshow_gallery', 'photo_gallery'].includes(
          options.type
        )
          ? 'gallery'
          : 'article'
      },
      text: {
        title: {
          style: css`
            font-size: 16px;
            line-height: 1.25;

            letter-spacing: 0.1px;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          `
        },
        color: options.baseColor,
        taxonomy: {
          headerTaxonomies: css`
            ${theme.font.family('boldWeb')};
            font-weight: 500;
            color: rgb(85, 85, 85);
            display: inline-block;
            font-size: 10px;
            text-decoration: none;
            line-height: 1rem;
            letter-spacing: 0.3em;
            margin: 0 0 15px 0;
            text-transform: uppercase;
            visibility: visible;
            a {
              color: inherit;
              &:hover {
                text-decoration: underline;
              }
            }
          `
        }
      }
    }
  }),
  focused: options => ({
    container: css`
      width: 100%;
      position: relative;
      margin-bottom: 0;
      padding-bottom: 2rem;
    `,
    imageContainer: css`
      width: 100%;
      position: relative;
      margin-bottom: 15px;
      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background: rgba(0, 0, 0, 0.3);
      }
      &::after {
        content: '';
        position: absolute;
        top: 25%;
        right: 0;
        width: 25%;
        height: 75%;
        background: rgba(0, 0, 0, 0.3);
        ${theme.mq.tablet} {
          width: 50%;
        }
      }
    `,
    textContainer: css`
      width: 100%;
      padding: 0 56px 0 30px;
      z-index: ${theme.zIndex('above')};
      ${theme.mq.tablet} {
        width: 50%;
        position: absolute;
        bottom: 0;
        ${options.textAlign === 'right' ? 'left: 0;' : 'right: 0;'}
        padding: 10px 40px 30px 30px;
        background-color: ${options.backgroundColor === 'black'
          ? '#000'
          : '#fff'};
      }
      ${theme.mq.small_desktop} {
        padding: 10px 65px 30px 30px;
      }
      ${theme.mq.large_desktop} {
        padding: 10px 60px 40px 40px;
      }
    `,
    card: {
      image: {
        aspect: options.aspect || '16x9',
        videoIconSize: 'l'
      },
      text: {
        title: {
          style: css`
            font-size: 1.5rem;
            line-height: 1.5;
            ${theme.mq.tablet} {
              font-size: 1.625rem;
              line-height: 1.5;
            }
            ${theme.mq.small_desktop} {
              font-size: 2.125rem;
            }
            ${theme.mq.large_desktop} {
              font-size: 3rem;
              line-height: 1.143;
            }
          `
        },
        color: facePaint({
          color: options.backgroundColor === 'black' ? '#fff' : '#000'
        }),
        taxonomy: {
          borderColorLeft: facePaint({
            'border-left':
              options.backgroundColor === 'black'
                ? '1px solid #fff'
                : '1px solid #555'
          }),
          borderColorRight: facePaint({
            'border-right':
              options.backgroundColor === 'black'
                ? '1px solid #fff'
                : '1px solid #555'
          }),
          margin: '0 0 15px 0'
        }
      }
    }
  }),
  leftSmall: options => {
    const styles = getStylesBySize.small(options);
    styles.textContainer = css`
      ${facePaint({ width: ['79%', '71%', '74%', '74%'] })}
    `;
    styles.card.text.title.style = css`
      font-size: 1rem;
      line-height: 1.5;
      ${theme.mq.tablet} {
        font-size: 0.875rem;
        line-height: 1.429;
      }
      ${theme.mq.small_desktop} {
        font-size: 1rem;
        line-height: 1.5;
      }
    `;
    return styles;
  },
  leftMedium: options => {
    const styles = getStylesBySize.leftSmall(options);
    styles.card.text.title.style = css`
      font-size: 1.375rem;
      line-height: 1.5;
      ${theme.mq.large_desktop} {
        font-size: 1.75rem;
      }
    `;
    return styles;
  },
  photoThird: options => {
    const styles = getStylesBySize.third(options);
    styles.card.text.title.style = css`
      ${theme.font.family('regularWeb')};
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.1px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    `;
    return styles;
  },
  photoLarge: options => {
    const styles = getStylesBySize.large(options);
    styles.card.text.title.style = css`
      ${theme.font.family('regularWeb')};
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.1px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    `;
    styles.card.text.color = options.baseColor;
    styles.textContainer = css`
      width: 95%;
      padding-left: 20px;
      padding-right: 20px;
    `;
    return styles;
  },
  gridMain: options => ({
    container: css`
      width: 100%;
      position: relative;
      div[data-icon-type='card-image-gallery'] {
        display: none;
      }
    `,
    imageContainer: css`
      width: 100%;
      position: relative;
      picture:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.27) 20.83%,
          rgba(0, 0, 0, 0.58) 42.19%,
          rgba(0, 0, 0, 0.74) 74.48%,
          rgba(0, 0, 0, 0.95) 100%
        );
        z-index: 0;
      }
    `,
    textContainer: css`
      width: auto;
      padding: 0 20px 56px 20px;
      z-index: ${theme.zIndex('share')};
      position: absolute;
      bottom: 0;
      left: 0;
      ${options.type === 'video' &&
      `
        ${theme.mq.tablet} {
          margin-left: 110px;
        }
        ${theme.mq.small_desktop} {
          margin-left: 130px;
        }
        ${theme.mq.large_desktop} {
          margin-left: 140px;
        }
        `}
    `,
    card: {
      image: {
        aspect: options.aspect || '16x9',
        videoIconSize: 'l'
      },
      text: {
        title: {
          style: css`
            font-size: ${theme.font.size('xl')};
            line-height: ${theme.line.height('s')};
            letter-spacing: ${theme.letterSpacing('xs2')};
            ${theme.mq.tablet} {
              font-size: ${theme.font.size('xl2')};
              line-height: ${theme.line.height('base')};
            }
            ${theme.font.family('regularWeb')};
            font-weight: bold;
            margin-bottom: ${theme.spacing('s')};
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          `
        },
        color: 'color: #fff;',
        cta: css`
          position: absolute;
          bottom: 0;
          margin-bottom: 20px;
        `
      }
    }
  }),
  grid: options => {
    const styles = getStylesBySize.third(options);
    styles.container = css`
      width: 100%;
      height: 100%;
      background-color: ${options.backgroundColor};
      border: 1px solid #e6e6e6;
      position: relative;
    `;
    styles.textContainer = css`
      width: 95%;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 84px;
    `;

    styles.card.text.title.style = css`
      font-size: 1.375rem;
      line-height: 1.273;
      ${theme.mq.tablet} {
        font-size: 1.125rem;
        letter-spacing: ${theme.letterSpacing('s')};
        line-height: 1.429;
        line-height: 28px;
      }
      ${theme.mq.large_desktop} {
        font-size: 1.375rem;
        letter-spacing: 0.1px;
        line-height: 28px;
      }
      margin-bottom: 20px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `;
    styles.card.text.cta = css`
      position: absolute;
      bottom: 0;
      margin-bottom: 20px;
    `;
    return styles;
  },
  'portrait-with-cta-on-top': options => {
    const styles = getStylesBySize.third(options);
    styles.container = css`
      ${styles.container}
      background-color: #000;
      max-height: 603px;
      overflow: hidden;
    `;
    styles.imageContainer = css`
      ${styles.imageContainer}
      opacity: 0.6;
      margin-bottom: 0;
    `;
    styles.textContainer = css`
      ${styles.textContainer}
      width:100%;
      text-align: center;
      z-index: 1;
      position: absolute;
      bottom: 10%;
    `;
    styles.card.text.title.style = css`
      color: #fff;
      margin-bottom: ${theme.spacing('xl3')};
      letter-spacing: ${theme.letterSpacing('base')};
    `;
    return styles;
  },
  'landscape-with-hover': options => {
    const styles = getStylesBySize.third(options);
    styles.container = css`
      ${styles.container}
      background-color: #000;
      overflow: hidden;
    `;
    styles.imageContainer = css`
      ${styles.imageContainer}
      opacity: 0.7;
      margin-bottom: 0;
      picture {
        transition: all 800ms;
      }
      picture:hover {
        transform: scale(1.1);
      }
    `;
    styles.textContainer = css`
      ${styles.textContainer}
      width: calc( 100% - 60px );
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 1;
      position: absolute;
    `;
    styles.card.text.title.style = css`
      color: #fff;
      margin-bottom: 0;
      letter-spacing: ${theme.letterSpacing('base')};
      text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
      :hover {
        color: #fff;
      }
    `;
    return styles;
  },
  'recommendation-box': options => {
    const styles = getStylesBySize.small(options);
    styles.textContainer = css`
      width: 95%;
      margin-left: 0;
    `;
    styles.card.text.title.style = css`
      font-size: ${theme.font.size('base')};
      line-height: ${theme.line.height('l')};
      letter-spacing: ${theme.letterSpacing('xs')};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    `;
    styles.card.text.color = 'color: #fff';
    styles.card.text.taxonomy.headerTaxonomiesColor = 'color: #ccc';
    styles.card.text.taxonomy.footerTaxonomies = 'display:none';

    return styles;
  }
};

const addShowStyles = style => {
  const showStyles = style;
  showStyles.imageContainer = css`
    width: 100%;
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: '';
      background: rgba(0, 0, 0, 0.5);
      z-index: ${theme.zIndex('above')};
    }
    &::before {
      top: 0;
      width: 113px;
      height: 80px;
      ${theme.mq.tablet} {
        width: 125px;
        height: 87px;
      }
      ${theme.mq.large_desktop} {
        width: 152px;
        height: 108px;
      }
    }
    &::after {
      bottom: 0;
      right: 0;
      width: 142px;
      height: 134px;
      ${theme.mq.tablet} {
        width: 129px;
        height: 120px;
      }
      ${theme.mq.large_desktop} {
        width: 159px;
        height: 150px;
      }
    }
  `;

  showStyles.container = css`
    width: 100%;
    position: relative;
  `;

  showStyles.textContainer = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: ${theme.zIndex('above')};
  `;

  showStyles.card.text.title.style = css`
    ${theme.font.family('regularWeb')};
    font-weight: 400;
    letter-spacing: 2.5px;
    line-height: 1.5;
    text-transform: uppercase;
    font-size: 1.375rem;

    display: table;
    position: relative;
    left: 5%;
    text-align: left;
    margin: 0;
    ${theme.mq.small_desktop} {
      font-size: 1.1875rem;
    }
    ${theme.mq.large_desktop} {
      font-size: 1.375rem;
    }
    &::before {
      height: 16px;
      left: -30px;
      top: 5px;
      content: '';
      height: 35px;
      width: 3px;
      display: block;
      position: absolute;
      background: #fc0;
    }
    ${theme.mq.tablet} {
      height: 18px;
      top: 4px;
    }
    ${theme.mq.small_desktop} {
      height: 14px;
      left: -20px;
      top: 3px;
    }
    ${theme.mq.large_desktop} {
      top: 3px;
      height: 17px;
      left: -30px;
    }
  `;
  showStyles.card.text.color = 'color: #fff';
  return showStyles;
};

export const getStyles = (size, backgroundColor, type, aspect, textAlign) => {
  if (!size) {
    return baseStyles;
  }
  const baseColor = `color: ${
    backgroundColor === 'black' || backgroundColor === 'gray' ? '#fff' : '#000'
  };`;
  const baseBorderColor =
    backgroundColor === 'black' || backgroundColor === 'gray'
      ? '1px solid #fff'
      : '1px solid #555';

  const style = getStylesBySize[size]({
    backgroundColor,
    type,
    aspect,
    textAlign,
    baseColor,
    baseBorderColor
  });

  if (type === 'show') {
    return addShowStyles(style);
  }
  return style;
};
