import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { PhotoOfTheDayParagraph } from '../Paragraph/PhotoOfTheDayParagraph';
import { dateService } from '../../domain/services/dateService';
import { CardItemVerticalLegacy } from '../responsive/organisms/CardItemVerticalLegacy';
import { GridColumn, GridRow } from '../responsive/atoms/Grid';
import { ContainerMain } from '../responsive/atoms/ContainerMain';
import { podArchive } from '../Styles/pod';
import { theme } from '../../domain/theme';

const cardTag = css`
  ${theme.font.family('regularWeb')};
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 3px;
  line-height: 1.5;

  display: inline-block;
  padding: 0 25px;
  border-right: 1px solid #555;
  color: #000;

  &:first-of-type {
    border-left: 1px solid #555;
  }

  & a:link,
  & a:visited {
    color: inherit;
  }

  & a:hover & a:active & a:visited {
    color: inherit;
  }

  & a:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${theme.mq.tablet} {
    & {
      padding: 0 20px;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      padding: 0 30px;
    }
  }

  ${theme.mq.large_desktop} {
    & {
      padding: 0 20px;
    }
  }
`;
const getPodIndexPage = (firstIndex, data) => {
  const blockElements = data.filter((_element, index) => {
    if (index > firstIndex && index % 10 !== 0 && index < firstIndex + 10) {
      return true;
    }
    return false;
  });
  return { elements: blockElements, header: data[firstIndex] };
};

const getAllPages = data => {
  const podPages = [];
  let mustContinue = true;
  let nextIndex = 0;
  while (mustContinue) {
    const podPage = getPodIndexPage(nextIndex, data);
    if (podPage.elements.length === 0) {
      mustContinue = false;
    } else {
      podPages.push(podPage);
      nextIndex += 10;
    }
  }
  return podPages;
};

export const PodIndex = ({ data }) => {
  const podPages = getAllPages(data);

  return (
    <>
      {podPages.map((podPage, index) => {
        let preTitle;
        if (index === 0) {
          preTitle = dateService.format(Date.now(), 'long');
        } else {
          preTitle = (
            <div
              className="card__tag"
              css={cardTag}
              style={{ borderColor: '#fff' }}
            >
              <a
                href={podPage.header.hubpage.url}
                style={{
                  color: '#fff',
                  textTransform: 'uppercase',
                  fontSize: '14px'
                }}
              >
                {podPage.header.hubpage.title}
              </a>
            </div>
          );
        }
        return (
          <React.Fragment // eslint-disable-next-line
            key={index}
          >
            <div css={podArchive}>
              {index === 0 ? (
                <PhotoOfTheDayParagraph
                  title={podPage.header.title}
                  imageEntity={podPage.header.image}
                  header={podPage.header.image.mediaImage.title}
                  galleryUrl={podPage.header.url}
                  preTitle={preTitle}
                />
              ) : (
                <PhotoOfTheDayParagraph
                  imageEntity={podPage.header.image}
                  header={podPage.header.title}
                  galleryUrl={podPage.header.url}
                  preTitle={preTitle}
                />
              )}
              <ContainerMain>
                <GridRow gutter="xs">
                  {podPage.elements.map(pod => {
                    return (
                      <GridColumn
                        width={[12, 4, 4, 4]}
                        childrenMarginBottom="l"
                        key={`${pod.month}${pod.year}`}
                      >
                        <CardItemVerticalLegacy
                          size="third"
                          data={{
                            type: 'article',
                            url: pod.url,
                            title: pod.title,
                            imageEntity: pod.image,
                            taxonomies: [
                              {
                                id: 0,
                                name: pod.hubpage.title,
                                url: pod.hubpage.url,
                                isPtt: true
                              }
                            ]
                          }}
                          aspect="16x9"
                          imageMaxWidths={{
                            mobile: 703,
                            tablet: 309,
                            small_desktop: 378,
                            large_desktop: 485
                          }}
                        />
                      </GridColumn>
                    );
                  })}
                </GridRow>
              </ContainerMain>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

PodIndex.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};
