import React from 'react';
import PropTypes from 'prop-types';
import { CardItemLegacy } from '../CardItemLegacy';
import { removeAttributesFromCardData } from '../../../../domain/utils/contentUtils';
import { getStyles } from './styles';

export const CardItemVerticalLegacy = ({
  data,
  imageMaxWidths,
  gtmClickEvent,
  backgroundColor,
  size,
  aspect,
  isAboveTheFold,
  textAlign,
  canBeAnimated,
  displayShowAsArticle,
  keepTaxonomies
}) => {
  const type =
    displayShowAsArticle && data.type === 'show' ? 'article' : data.type;
  const styles = getStyles(size, backgroundColor, type, aspect, textAlign);
  const cardData = data;
  if (size === 'large' && cardData.sponsored) {
    cardData.sponsored.bgColor = 'grey';
  }
  return (
    <CardItemLegacy
      data={
        size && cardData.type === 'show' && !keepTaxonomies
          ? removeAttributesFromCardData(cardData, ['taxonomies'])
          : cardData
      }
      imageMaxWidths={imageMaxWidths}
      containerStyles={styles.container}
      imageContainerStyles={styles.imageContainer}
      textContainerStyles={styles.textContainer}
      gtmClickEvent={gtmClickEvent}
      styles={styles.card}
      isAboveTheFold={isAboveTheFold}
      canBeAnimated={canBeAnimated}
    />
  );
};
CardItemVerticalLegacy.defaultProps = {
  gtmClickEvent: undefined,
  imageMaxWidths: undefined,
  backgroundColor: 'white',
  size: 'small',
  aspect: undefined,
  isAboveTheFold: false,
  textAlign: undefined,
  canBeAnimated: false,
  displayShowAsArticle: false,
  keepTaxonomies: true
};

CardItemVerticalLegacy.propTypes = {
  data: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
  imageMaxWidths: PropTypes.objectOf(PropTypes.any),
  gtmClickEvent: PropTypes.objectOf(PropTypes.any),
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf([
    'large',
    'medium',
    'small',
    'third',
    'list',
    'focused',
    'leftSmall',
    'leftMedium',
    'photoThird',
    'photoLarge',
    'gridMain',
    'grid',
    'portrait-with-cta-on-top',
    'landscape-with-hover',
    'recommendation-box',
    'content-package'
  ]),
  aspect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string)
  ]),
  isAboveTheFold: PropTypes.bool,
  textAlign: PropTypes.oneOf(['right', 'left']),
  canBeAnimated: PropTypes.bool,
  displayShowAsArticle: PropTypes.bool,
  keepTaxonomies: PropTypes.bool
};
