import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const PhotoOfTheDayParagraphLoadable = loadable(
  () => import('./PhotoOfTheDayParagraph'),
  {
    resolveComponent: components => components.PhotoOfTheDayParagraph
  }
);

export const PhotoOfTheDayParagraph = WithLazyHydration(
  PhotoOfTheDayParagraphLoadable,
  {
    name: 'PhotoOfTheDayParagraph'
  }
);
