import React from 'react';
import { WithIcon } from './WithIcon';

export const IconPlus = WithIcon(
  'Plus',
  '-2 -2 40 40',
  <path
    fill="none"
    stroke="currentColor"
    strokeMiterlimit="10"
    strokeWidth="2"
    vectorEffect="non-scaling-stroke"
    d="M0 18h36M18 0v36"
    // Use shapeRendering="crispEdges" to avoid antialiasing and make borders really crispy
  />
);
