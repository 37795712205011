import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './Container';
import { theme } from '../../../domain/theme';

export const ContainerMain = ({
  backgroundColor,
  backgroundImage,
  backgroundImageOpacity,
  children
}) => {
  const container = theme.grid.containers('main');
  return (
    <Container
      maxWidth={container.maxWidth}
      padding={container.padding}
      childrenMarginBottom={container.childrenMarginBottom}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundImageOpacity={backgroundImageOpacity}
    >
      {children}
    </Container>
  );
};

ContainerMain.defaultProps = {
  backgroundColor: undefined,
  backgroundImage: undefined,
  backgroundImageOpacity: undefined
};

ContainerMain.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.any,
  backgroundImageOpacity: PropTypes.any,
  children: PropTypes.node.isRequired
};
