import React from 'react';
import PropTypes from 'prop-types';
import WithLayout from '../WithLayout';
import { PodIndex } from '../../components/Pod/PodIndex';

const PodIndexPage = ({ content }) => {
  return <PodIndex data={content.data} />;
};

PodIndexPage.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
export default WithLayout(PodIndexPage);
